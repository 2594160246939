import React from 'react'
import faq from '../../../assets/images/landing-img/faq.png';

const FrequentQuestions = () => {
  return (
    <section className="mbc-space">
        <div className="container">
            <div className="title text-center mbc-space">
                <h2>Curious? Here Are Some Quirky Questions We Get All the Time!</h2>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <img src={faq} alt="" className="img-fluid w-75 d-block mx-auto pcEl"/>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="accordion" id="techStacki">
                        <div className="accordion-item faq-job-item coruse-item mt-0 border-top border-bottom">
                            <h2 className="accordion-header" id="headingeOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeOne" aria-expanded="true" aria-controls="collapseeOne">
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className="faqQuestion">
                                        <h6>Is there any requirement to enroll in this course? </h6>
                                    </div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseeOne" className="accordion-collapse collapse show" aria-labelledby="headingeOne" data-bs-parent="#techStacki">
                                <div className="accordion-body">
                                    <p className="faqPara">There is no prior requirement, any tech graduate can join this course. Apart from that, basic programming knowledge, preferably in C#, is recommended.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item coruse-item">
                            <h2 className="accordion-header" id="headingeTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeTwo" aria-expanded="true" aria-controls="collapseeTwo">
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className="faqQuestion">
                                        <h6>Are there any hands-on projects included in the curriculum? </h6>
                                    </div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseeTwo" className="accordion-collapse collapse" aria-labelledby="headingeTwo" data-bs-parent="#techStacki">
                                <div className="accordion-body">
                                    <p className="faqPara">Absolutely. The course includes several hands-on projects and a capstone project to apply your learning in real-world scenarios.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item coruse-item">
                            <h2 className="accordion-header" id="headingeThree">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeThree" aria-expanded="true" aria-controls="collapseeThree">
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className="faqQuestion">
                                        <h6>How relevant is this course to current industry demands?</h6>
                                    </div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseeThree" className="accordion-collapse collapse" aria-labelledby="headingeThree" data-bs-parent="#techStacki">
                                <div className="accordion-body">
                                    <p className="faqPara">This course is designed to meet current industry needs, focusing on technologies and practices widely used in modern software development.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item coruse-item">
                            <h2 className="accordion-header" id="headingeFour">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeFour" aria-expanded="true" aria-controls="collapseeFour">
                                <div className="d-flex justify-content-between align-items-start">
                                    <div className="faqQuestion">
                                        <h6>Is this course expensive to join?</h6>
                                    </div>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseeFour" className="accordion-collapse collapse" aria-labelledby="headingeFour" data-bs-parent="#techStacki">
                                <div className="accordion-body">
                                    <p className="faqPara">No, this course is not expensive. In fact, various discounts are available for those who will join this course early.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item coruse-item">
                            <h2 className="accordion-header" id="headingeFive">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeFive" aria-expanded="true" aria-controls="collapseeFive">
                                <div className="faqQuestion">
                                    <h6>Can I get job placement assistance after completing the course? </h6>
                                </div>
                            </button>
                            </h2>
                            <div id="collapseeFive" className="accordion-collapse collapse" aria-labelledby="headingeFive" data-bs-parent="#techStacki">
                                <div className="accordion-body">
                                    <div className="d-flex justify-content-between align-items-start">
                                        <p className="faqPara">While we don't guarantee job placement, we use our network of industry partners to support your job search.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default FrequentQuestions