import React, { useState } from 'react'
import QueryForm from '../../../components/query-form/QueryForm';

const Carousel = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <section className="mtc-space banner" id="Contactform">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 colmd-12 col-sm-12">
            <div className="content">
              <div className="heroContent">
                <h2>Unlock Your Tech Stack Potential with</h2>
                <h1>
                  <span className="text-primary">100% Self-Placement Support</span>
                </h1>
                <h3>Transform Your Skills, Transform Your Future!</h3>
              </div>
              <div className="d-flex align-items-center rating-img">
                <div className="key-highlights">
                  <span className="numhiglights text-primary">12+</span> <br /> Years Industry Experts
                </div>
                <div className="key-highlights">
                  <span className="numhiglights text-primary">200+</span> <br /> Professionals Trained
                </div>
                <div className="key-highlights">
                  <span className="numhiglights text-primary">100%</span> <br /> Placement Assistance
                </div>
                <div className="key-highlights">
                  <span className="numhiglights text-primary">24x7</span> <br /> Learners Support
                </div>
              </div>
              <button onClick={openModal} className="btn btn-main">
                Enquire now
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="formWrapper">
              <div className="row">
                <div className="col-lg-9 col-md-10 col-sm-12 ms-auto">
                  <div className="formWrapper">
                    <form method="POST" id="web-development">
                      {/* <div className="offerImg">
                        <img src="https://www.skillsuptech.com/assets/images/offer.png" alt="" className="img-fluid" />
                      </div> */}
                      <h4 className="form-title">Let's talk about your career growth!</h4>
                      <div className="input-group">
                        <span className="input-group-text">+91</span>
                        <input type="number" className="form-control mobileNumber" name="mobileNumber" placeholder="Mobile" aria-label="Mobile" min-length="10" max-length="10" />
                      </div>
                      <div className="input-wrapper"><label>Full Name<span className="red">*</span></label>
                        <input type="text" name="lName" className="form-control lName" placeholder="Full Name" />
                      </div>
                      <div className="input-wrapper">
                        <label>Email <span className="red">*</span></label>
                        <input type="email" name="email" className="form-control email" placeholder="eg.yourname@gmail.com" />
                      </div>
                      <div className="input-wrapper">
                        <label>Your Query<span className="red">*</span></label>
                        <textarea name="message" className="form-control message" placeholder="Your Query"></textarea>
                      </div>
                      <div className="input-wrapper mb-0">
                        <button type="submit" className="btn btn-main" id="myForm1">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QueryForm isOpen={isModalOpen} onClose={closeModal} />
    </section>
  )
}

export default Carousel