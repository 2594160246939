import React from 'react'
import perfact from '../../../assets/images/landing-img/perfect.png';
import expert from '../../../assets/images/landing-img/expert.png';
import professional from '../../../assets/images/landing-img/professional.png';
import support from "../../../assets/images/landing-img/support.png";
import project from '../../../assets/images/landing-img/project.png';
import classes from "../../../assets/images/landing-img/classes.png";
import session from "../../../assets/images/landing-img/session.png";
import completion from "../../../assets/images/landing-img/completion.png";

const WhyChooseUs = () => {
  return (
    <section className="mbc-space">
      <div className="container">
        <div className="title text-center mbc-space">
          <h2>Why Choose Us?</h2>
        </div>
        <div className="row">
          <ul className="list-unstyled whyChoose">
            <li><span><img src={perfact} alt=""
              className="img-fluid" /></span>
              <p>100% Placement Assistance</p>
            </li>
            <li><span><img src={expert} alt=""
              className="img-fluid" /></span>
              <p>12+ Years Industry Experts</p>
            </li>
            <li><span><img src={professional}
              alt="" className="img-fluid" /></span>
              <p>200+ Professionals Trained</p>
            </li>
            <li><span><img src={support} alt=""
              className="img-fluid" /></span>
              <p>Learning Support</p>
            </li>
            <li><span><img src={project} alt=""
              className="img-fluid" /></span>
              <p>Work on Live Projects</p>
            </li>
            {/* <li><span><img src="https://www.skillsuptech.com/assets/images/landing-img/certificate.png" alt=""
              className="img-fluid" /></span>
              <p>Get an IT Company Experience Certificate</p>
            </li> */}
            <li><span><img src={classes} alt=""
              className="img-fluid" /></span>
              <p>Online/ Offline Classes</p>
            </li>
            <li><span><img src={session} alt=""
              className="img-fluid" /></span>
              <p>Problem Solving Sessions</p>
            </li>
            <li><span><img src={completion} alt=""
              className="img-fluid" /></span>
              <p>Certificate of Completion</p>
            </li>
            {/* <li><span><img src="https://www.skillsuptech.com/assets/images/landing-img/aws.png" alt=""
              className="img-fluid" /></span>
              <p>Knowledge of AWS</p>
            </li> */}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs