import React, { useState } from 'react';
import trainedstudent from '../../../assets/images/landing-img/web-development/trained-students.svg';
import industryexpert from '../../../assets/images/landing-img/web-development/industries-experience.svg';
import clock from '../../../assets/images/landing-img/web-development/icon/clock.svg';
import jobassistence from '../../../assets/images/landing-img/web-development/job-assistence.svg';
import curriculumData from '../../../data/curriculum.json';

const Curriculum: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Default to first item

  const handleToggle = (index: number) => {
    if (index === activeIndex) {
      // Hide the current and show the next
      const nextIndex = (index + 1) % curriculumData.length;
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(index); // Set clicked item as active
    }
  };

  return (
    <section className="pyc-space bg-dark mbc-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mbc-space">
            <div className="title text-center">
              <h2>Curriculum that makes our course stand apart</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-10 col-sm-12 mx-auto">
            <div className="card curi-bx bg-main">
              <h3>Built for Your Career!</h3>
              <p>Crafted by industry experts, this course delivers a cutting-edge curriculum.</p>
              <div className="card inner-curi-card d-flex flex-row rounded">
                <img src={trainedstudent} alt="" className="img-fluid" />
                <div className="contentWrapper">
                  <h4>1,000+</h4>
                  <p className="mbc-0">Trained Students</p>
                </div>
              </div>
              <div className="card inner-curi-card d-flex flex-row rounded">
                <img src={industryexpert} alt="" className="img-fluid" />
                <div className="contentWrapper">
                  <h4>2+</h4>
                  <p className="mbc-0">Years of Industry Experience</p>
                </div>
              </div>
              <div className="card inner-curi-card d-flex flex-row rounded">
                <img src={jobassistence} alt="" className="img-fluid" />
                <div className="contentWrapper">
                  <h4>100%</h4>
                  <p className="mbc-0">Job Assistance</p>
                </div>
              </div>
              <a href="#" className="btn btn-main" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Download Brochure
              </a>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="accordion" id="techStack">
              {curriculumData
                .filter((course) => course.isActive) // Filter by isActive
                .map((course, index) => (
                  <div className="accordion-item faq-item coruse-item" key={course.id}>
                    <h2 className="accordion-header" id={`heading${course.id}`}>
                      <button
                        className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => handleToggle(index)}
                        aria-expanded={activeIndex === index}
                        aria-controls={`collapse${course.id}`}
                      >
                        <div className="d-flex justify-content-between align-items-center w-75 coruseItemFlxWrapper">
                          <div className="flcf d-flex align-items-center">
                            <p className="mb-0">{course.courseName}</p>
                          </div>
                          <div className="flcs d-flex align-items-center">
                            <img src={clock} alt="" />
                            <p className="mb-0">{course.duration}</p>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`collapse${course.id}`}
                      className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                      aria-labelledby={`heading${course.id}`}
                      data-bs-parent="#techStack"
                    >
                      <div className="accordion-body">
                        <ul className="grid-list">
                          {course.topics.map((topic, topicIndex) => (
                            <li key={topicIndex}>{topic}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Curriculum;
