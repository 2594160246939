import React, { useState } from 'react'
import logo from '../../../assets/images/landing-img/web-development/logo.png';
import QueryForm from '../../../components/query-form/QueryForm';

const Navbar = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top" id="navbar">
        <div className="container">
          <a className="navbar-brand text-white" href="">
            <img src={logo} alt="" className="img-fluid logo" /></a>
          <button onClick={openModal} className="btn btn-main ms-auto">
            Apply Now
          </button>
          {/* <a href="#" className="btn btn-main ms-auto" data-bs-toggle="modal" data-bs-target="#formPop">Apply Now</a> */}
        </div>
      </nav>
      <QueryForm isOpen={isModalOpen} onClose={closeModal} />
    </>
  )
}
export default Navbar;
