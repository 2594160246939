import React from 'react';
import Navbar from './feature/landing-page/navbar/Navbar';
import Footer from './components/footer/Footer';
import Enquery from './feature/landing-page/footer-enquery/FooterEnquery';
import Carousel from './feature/landing-page/carousel/Carousel';
import Curriculum from './feature/landing-page/curriculum/Curriculum';
import UniqueFeatures from './feature/landing-page/unique-features/UniqueFeatures';
import WhyChooseUs from './feature/landing-page/why-choose-us/WhyChooseUs';
// import CertificateOfCompletion from './feature/landing-page/certificate-of-completion/CertificateOfCompletion';
import RoadmapToSuccess from './feature/landing-page/roadmap-to-success/RoadmapToSuccess';
import FrequentQuestions from './feature/landing-page/frequent-questions/FrequentQuestions';
import ToolsTechnologies from './feature/landing-page/tools-technologies/ToolsTechnologies';

function App() {
  return (
    <>
      <Navbar />
      <Carousel />
      <Curriculum />
      <UniqueFeatures />
      <ToolsTechnologies />
      <WhyChooseUs />
      {/* <CertificateOfCompletion /> */}
      <RoadmapToSuccess />
      <FrequentQuestions />
      <Footer />
      <Enquery />
    </>
  );
}

export default App;
