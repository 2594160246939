import React from 'react'
import placement from '../../../assets/images/landing-img/web-development/placement-assistance.svg';
import project from '../../../assets/images/landing-img/web-development/live-projects.svg';
import certificate from "../../../assets/images/landing-img/web-development/certificate.svg";
import classes from "../../../assets/images/landing-img/web-development/classes.svg";
import session from "../../../assets/images/landing-img/web-development/doubt-solving-sessions.svg";
import corporate from "../../../assets/images/landing-img/web-development/it-corporate.svg";
// import expertguidance from "../../../assets/images/landing-img/web-development/expert-guidance.svg";
import studymaterial from "../../../assets/images/landing-img/web-development/study-material.png"
import assesment from "../../../assets/images/landing-img/web-development/regular-assesments.svg";
import feedback from "../../../assets/images/landing-img/web-development/feedback.svg";

const UniqueFeatures = () => {
  return (
    <section className="mbc-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mbc-space">
            <div className="title text-center">
              <h2>Choose Our Skills Development Courses, <span className="text-primary">Unlock Your Future Today!</span></h2>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="courseCardsWrapper">
              <div className="card course-bx text-center">
                <img src={placement} alt="" />
                <p>100% Placement <br /> Assistance</p>
              </div>
              <div className="card course-bx text-center">
                <img src={project} alt="" />
                <p>Work on Live <br /> Projects</p>
              </div>
              <div className="card course-bx text-center">
                <img src={certificate} alt="" />
                <p>Certificate of <br /> Completion</p>
              </div>
              <div className="card course-bx text-center">
                <img src={classes} alt="" />
                <p>Online and <br /> Offline Classs</p>
              </div>
             <div className="card course-bx text-center">
                <img src={session} alt="" />
                <p>Doubt-solving <br /> Session</p>
              </div>
              <div className="card course-bx text-center">
                <img src={corporate} alt="" />
                <p>Corporate IT <br /> Training</p>
              </div>
              <div className="card course-bx text-center">
                {/* <img src={expertguidance} alt="" /> */}
                <p>Expert <br /> Guidance</p>
              </div>
               <div className="card course-bx text-center">
                <img src={studymaterial} alt="" />
                <p>Interactive Study <br /> Materials</p>
              </div>
              <div className="card course-bx text-center">
                <img src={assesment} alt="" />
                <p>Regular <br /> Assessments</p>
              </div>
              <div className="card course-bx text-center">
                <img src={feedback} alt="" />
                <p>Personalized <br /> Feedback</p>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UniqueFeatures;