import aws from '../../../assets/images/landing-img/web-development/aws.webp';
import azure from '../../../assets/images/landing-img/web-development/azure.jpg';
import dockerK8s from '../../../assets/images/landing-img/web-development/dockerk8s.jpg';
import csharp from '../../../assets/images/landing-img/web-development/c-sharp.jpg';
import dotnetcore from '../../../assets/images/landing-img/web-development/dotnet-core.jpg';
import python from '../../../assets/images/landing-img/web-development/python.jpg';
import nodejs from '../../../assets/images/landing-img/web-development/node-js.jpg';
import mongodb from '../../../assets/images/landing-img/web-development/mongodb.webp';
import angular from '../../../assets/images/landing-img/web-development/angular.webp';
import react from '../../../assets/images/landing-img/web-development/react.webp';
import reactnative from '../../../assets/images/landing-img/web-development/react-native.jpg';
import typesctipt from '../../../assets/images/landing-img/web-development/typescript.jpg';
import javasctipt from '../../../assets/images/landing-img/web-development/js.webp';
import bootstarp from '../../../assets/images/landing-img/web-development/boostrap.webp';
import css from '../../../assets/images/landing-img/web-development/css.webp';

const ToolsTechnologies = () => {
  return (
    <section className="mbc-space pyc-space">
      <div className="container">
        <div className="title text-center">
          <h2>Explore the Cutting-Edge Tools & Technologies You'll Master</h2>
        </div>
        <div className="row">
          <div className="plangWrapper">
            <div className="plangImg-item">
              <img src={aws} alt="aws" className="img-fluid" />
              <img src={azure} alt="azure" className="img-fluid" />
              <img src={dockerK8s} alt="docker & K8s" className="img-fluid" />
              <img src={csharp} alt="c sharp" className="img-fluid" />
              <img src={dotnetcore} alt="dotnet core" className="img-fluid" />
              <img src={python} alt="python" className="img-fluid" />
              <img src={nodejs} alt="node js" className="img-fluid" />
              <img src={mongodb} alt="mongodb" className="img-fluid" />
              <img src={angular} alt="angular" className="img-fluid" />
              <img src={react} alt="react" className="img-fluid" />
              <img src={reactnative} alt="react native" className="img-fluid" />
              <img src={typesctipt} alt="typesctipt" className="img-fluid" />
              <img src={javasctipt} alt="javasctipt" className="img-fluid" />
              <img src={css} alt="css" className="img-fluid" />
              <img src={bootstarp} alt="bootstarp" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ToolsTechnologies;